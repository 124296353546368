export default [
  {
    title: "Home",
    route: "home",
    icon: "HomeIcon",
  },
  // {
  //   title: "Second Page",
  //   route: "second-page",
  //   icon: "FileIcon",
  // },
  // {
  //   title: "Product KIT",
  //   route: "product-kit-list",
  //   icon: "FileIcon",
  // },
  {
    title: "Product",
    route: "product-list",
    icon: "CameraIcon",
  },
  {
    title: "Accessories",
    route: "accessories-list",
    icon: "FileTextIcon",
  },
  {
    title: "Order",
    route: "order-list",
    icon: "CameraIcon",
    children: [
      {
        title: "Order ทั้งหมด",
        route: "order-list",
      },
      {
        title: "รายการวันนี้",
        route: "order-pickup",
      },
      {
        title: "รายการคืน",
        route: "order-return",
      },
      {
        title: "สร้าง Order ใหม่",
        route: "order-create",
      },
    ],
  },
  {
    title: "รอดำเนินการ",
    route: "order-status",
    icon: "BellIcon",
    children: [
      {
        title: "รอยอดวางประกัน",
        route: "pending-deposit",
      },
      {
        title: "รอโทรแจ้งยอดวางประกัน",
        route: "pending-call",
      },
      {
        title: "ติดต่อไม่ได้",
        route: "not-response",
      },
      {
        title: "ลูกค้าแจ้งโอนเงิน",
        route: "transfered",
      },

      {
        title: "รอโอนประกันคืน",
        route: "pending-refund",
      },
    ],
  },
  {
    title: "ใบจอง",
    icon: "ClipboardIcon",
    route: "pre-book",
  },
  {
    title: "Order รอลูกค้าทำรายการ",
    route: "order-list",
    icon: "ClockIcon",
    children: [
      {
        title: "รอโอนค่าเช่า",
        route: "pending-transfer",
      },
      {
        title: "รอลูกค้าทำรายการ",
        route: "pending-info",
      },
      {
        title: "รอตอบกลับ",
        route: "pending-confirm",
      },
    ],
  },
  {
    title: "ใบกำกับภาษี",
    route: "tax-invoice-list",
    icon: "PrinterIcon",
  },

  {
    title: "บัญชี",
    route: "account",
    icon: "DollarSignIcon",
    children: [
      {
        title: "รายรับประจำวัน",
        route: "account-today",
      },
      {
        title: "รายรับแยกบัญชี",
        route: "account-each",
      },
      {
        title: "เงินประกัน",
        route: "deposit-list",
      },
      // {
      //   title: "Drive",
      //   route: "account-drive",
      // },
      {
        title: "ใบปิดยอด",
        route: "day-close",
      },
      // {
      //   title: "GG Drive",
      //   route: "pending-confirm",
      // },
      {
        title: "สมุดบัญชี",
        route: "book-bank-list",
      },
    ],
  },
  {
    title: "Member",
    route: "member-list",
    icon: "UsersIcon",
  },
  {
    title: "Queue",
    route: "queue-list",
    icon: "AlignCenterIcon",
  },
  {
    title: "Stat",
    route: "rental-stat",
    icon: "AlignCenterIcon",
  },
  {
    title: "Logs",
    route: "logs",
    icon: "ActivityIcon",
    children: [
      {
        title: "เช็คเงินประกัน ML",
        route: "deposit-predict-log",
      },
      {
        title: "จำนวนเช็คคิว",
        route: "check-log",
      },
      {
        title: "Facebook Price List",
        route: "facebook-pricelist",
      },
    ],
  },
  {
    title: "Tasks",
    route: "task-one",
    icon: "ListIcon",
    children: [
      {
        title: "Task",
        route: "task-one",
      },
      {
        title: "GG CONTACT",
        route: "goole-contact-init",
      },
    ],
  },
];
